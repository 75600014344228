@font-face {
  font-family: 'Millennium';
  src: url('millennium-bold-webfont.woff2') format('woff2'),
       url('millennium-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Millennium';
  src: url('millennium-italic_0-webfont.woff2') format('woff2'),
       url('millennium-italic_0-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Millennium';
  src: url('millennium-regular_0-webfont.woff2') format('woff2'),
       url('millennium-regular_0-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
