h1 img {
  max-width: 100%;
  height: auto;
}

.u-author, .invis {
  display: none;
}

body.default {
  font-family: "Verdana", sans-serif;
  font-size: 13pt;
  line-height: 120%;

  #main {
    padding: 60px 40px 20px 40px;
    max-width: 640px;
    margin: auto;
  }

  #sub p {
    margin: 20px 0;
  }

  #dir > hr, #focus hr {
    display: none;
  }

  #top {
    max-width: 460px;
    margin: 20px auto;
    font-weight: bold;
    text-align: center;
  }

  #menu {
    width: 100%;
    margin: auto;
  }

  #top, #sub, #focus {
    overflow: hidden;
    padding-bottom: 4px;
    clear: both;
  }

  #sub, #focus {
    margin: 20px 0;
  }

  #sub span {
    display: block;
    float: left;
    margin-right: 6px;
  }

  #Web a:link, #Web a:visited {
    color: #c44;
  }

  #Time-Depth a:link, #Time-Depth a:visited {
    color: #c80;
  }

  #focus {
    font-size: 11pt;
    border-top: solid 6px #eef;
    border-bottom: solid 6px #ffe;
    padding: 20px 0;
  }

  #about {
    h4 {
      text-align: center;
    }

    p {
      font-size: 11pt;
    }
  }

  a:link, a:visited {
    color: #c08;
    text-decoration: none;
    box-shadow: inset 0 0px 0 white, inset 0 -1px 0 red;
  }

  a:hover, a.active {
    color: black !important;
    box-shadow: inset 0 0px 0 white, inset 0 -1px 0 blue;
  }

  h1 {
    text-align: center;
    a {
      box-shadow: none !important;
    }
  }

  h3 {
    text-transform: uppercase;
    text-align: center;
    font-size: 9pt;
  }

  h4,
  .meta {
    display: inline;
    padding-right: 6px;
  }

  .fbr {
    word-break: break-all;
  }

  h4 a:link,
  h4 a:visited {
    // color: #f09;
    color: #086A87;
    box-shadow: inset 0 0px 0 white, inset 0 -1px 0 #01DF74;
  }

  p.intro {
    text-align: center;
    font-size: 9pt;
    background: white;
  }

  hr {
    color: #f09;
    width: 50%;
    margin: 10px auto;
  }

  .listings {
    -moz-column-count: 3;
    column-count: 3;
    column-width: 200px;
    column-gap: 20px;
  }

  .entry,
  .note {
    display: inline-block;
    margin: 0 0 20px 0;
    float: none;
  }

  .note {
    font-weight: bold;
    padding: 8px;
    color: #eef;
    background: #c80;
    box-shadow: 4px 3px 0 0 #8CC;

    a {
      color: #ffd;
      box-shadow: inset 0 0px 0 white, inset 0 -1px 0 #ffe;
    }

    p {
      margin-top: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .meta,
  .meta a {
    color: #c80 !important;
    font-style: italic;
    font-size: 9pt;
    box-shadow: none;
  }

  .meta a:hover {
    text-decoration: underline;
  }

  .entry {
    a {
      color: #80c;
    }

    blockquote {
      font-size: 10pt;
      font-style: italic;
      margin: 0;
      color: #527;
    }

    .descr {
      margin-top: -0.8rem;
    }

    .thumb {
      display: inline-block;
      background: #767;
      margin-top: 6px;
      border: solid 2px #bac;
      text-align: center;

      img {
        display: block;
        max-width: 100%;
        mix-blend-mode: screen;
        filter: grayscale(100%);
        opacity: 1;
      }
    }

    .domain {
      font-size: 9pt;
      text-align: center;
      word-wrap: break-word;
      width: 186px;
    }

    &:hover .thumb img {
      filter: none;
      mix-blend-mode: normal;
    }
  }
}

body.yellow {
  background: #fe0;
  font-family: monospace;
  font-size: 14pt;
  line-height: 120%;

  #main {
    padding: 20px;

    img {
      filter: grayscale(100%);
    }
  }

  a {
    color: black;
  }

  p.intro {
    font-size: 10pt;
    padding-bottom: 20px;
  }

  h1, h2, h3, #top a {
    font-size: 18pt;
    font-weight: bold;
  }

  h3 {
    display: inline-block;
    margin-bottom: 0;
    padding: 0 10px;
    border-left: solid 10px black;
    border-right: solid 10px black;
  }

  .note, .note a {
    background: black;
    color: #fe0;
  }

  h4 {
    margin: 0;
  }

  hr {
    display: none;
  }

  .entry {
    min-width: 250px;
    max-width: 400px;
    margin: 20px 20px 20px 0;
    float: left;
  }

  .entry img {
    width: 100%;
  }
}

body.olden {
  background: #bdbdbd;
  font-family: Millennium;
  font-size: 24px;
  line-height: 120%;
  font-smooth: never;
  -webkit-font-smoothing : none;

  #main {
    padding: 60px 40px 20px 40px;
    max-width: 840px;
    margin: auto;
  }

  #menu {
    text-align: center;
  }

  #top span:after {
    content: "\2022";
    font-size: 58px;
    padding: 0 12px 0 6px;
  }

  #top span:last-of-type:after {
    content: ""
  }

  #sub {
    margin-top: 10px;
  }

  hr {
    margin: auto;
    margin-top: 24px;
    border: inset 6px;
    text-align: center;
    width: 60%;
  }

  .thumb img {
    image-rendering: pixelated;
  }

  .thumb img {
    border: inset 6px;
  }

  a {
    color: blue;
  }

  p.intro {
    padding-bottom: 20px;
  }

  h1, h2, h3, #top a {
    font-size: 32px;
    font-weight: bold;
    line-height: 140%;
  }

  h3 {
    margin-top: 20px;
  }

  h3, .note {
    color: black;
  }

  h4 {
    margin-bottom: 0;
  }

  .meta {
    font-size: 18px;
    color: #555;
    text-transform: uppercase;
    font-weight: bold;
  }

  .meta a {
    color: #555;
  }

  .note {
    color: white; // #f39;
    padding: 12px;
    background-color: #f00; // #fce;
  }

  .note p {
    margin-top: 0;
  }

  .note p:last-of-type {
    margin-bottom: 0;
  }

  .note a {
    color: #ffc;
    font-style: italic;
  }

  .entries {
    -moz-column-count: 3;
    column-count: 3;
    column-width: 200px;
    column-gap: 20px;
  }

  .entry {
    display: inline-block;
    max-width: 100%;
    margin: 20px 20px 20px 0;

    img {
      width: 100%;
    }

    blockquote {
      font-size: 14px;
      margin: 0;
    }
  }
}

canvas {
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
